import Image from "next/image";
import p1 from "./p1.svg";
import p2 from "./p2.svg";
import p3 from "./p3.svg";
import px from "./p4.svg";
import { memo, useMemo } from "react";

const switchIcon = (p) => {
  if (p === 1) return p1;
  if (p === 2) return p2;
  if (p === 3) return p3;
  return px;
};

function Icon({ p = 4, width = 28, height = 28 }) {
  const img = useMemo(() => switchIcon(+p), [p]);

  const x = (img.width * width) / 28; // 28 = inner hexagon size
  const y = (img.height * height) / 28;

  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
        width: x,
        height: y,
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: ".8em",
          zIndex: 1,
          opacity: p < 4 ? 1 : 0.8,
          fontVariantNumeric: "tabular-nums",
        }}
      >
        {p}
      </div>
      <Image
        loading="lazy"
        src={img.src}
        alt={`An icon representing the ${p}th place.`}
        width={x}
        height={y}
      />
    </div>
  );
}

export default memo(Icon);
