import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { timeLeft } from './timer';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function removeDateOffset(date: Date | string | number) {
  const d = new Date(date);
  return new Date(d.getTime() + (d.getTimezoneOffset() * 60000));
};

export const getTimeRemaining = (currentTime: Date | number, utcDate: Date | string | number): string => {
  const timeRemaining = timeLeft(currentTime, utcDate);
  if (!timeRemaining) return "";

  const { days, hours, minutes, seconds } = timeRemaining;

  const timeFormats = [
    { condition: days >= 1, format: () => `${days}d ${hours}h ${minutes}m` },
    { condition: hours >= 1, format: () => `${hours}h ${minutes}m` },
    { condition: minutes >= 7, format: () => `${minutes}m` },
    { condition: minutes >= 1, format: () => `${minutes}m ${seconds}s` },
    { condition: true, format: () => `${seconds}s` }
  ];

  console.log({
    timeRemaining,
    timeFormats
  })

  return timeFormats.find(({ condition }) => condition)?.format() || "";
};