import "tippy.js/animations/scale.css";
import "tippy.js/animations/shift-away-subtle.css";
import "tippy.js/dist/tippy.css"; // optional for sstyling

import Tippy from "@tippyjs/react";

import st from "../styles/items/ToolTip.module.scss";
import { useRouter } from "next/router";
import { useState, useEffect, useRef, ReactNode } from "react";

export default function ToolTip({
  children,
  text = "" as ReactNode | string,
  placement,
  size = "small",
  transparent,
  forceShow = false,
  showMode = false,
  innerContent = false as ReactNode | false,
  extraClass = false as string | false,
  extraClasses = false,
  ignoreRouteChange = false,
  disabled = false,
}) {
  // Create refs to store the Tippy instances
  const tippyInstanceRef = useRef(null);
  const toolTip = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const router = useRouter();
  const content = innerContent ? (
    innerContent
  ) : (
    <span
      className={`${st["tippy-container"]} ${extraClass ? st[extraClass] : ""} ${st[size]} ${
        transparent ? st["transparent"] : ""
      }`}
    >
      {text}
    </span>
  );

  //Removes tippy when router changes
  useEffect(() => {
    const handleRouteChange = () => {
      if (!ignoreRouteChange) {
        setIsVisible(false); // Close the Tippy tooltip
      }
    };
    if (!forceShow) {
      return;
    }

    // Listen for route changes
    router.events.on("routeChangeStart", handleRouteChange);

    // Cleanup
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router, forceShow, ignoreRouteChange]);

  return (
    <Tippy
      placement={placement}
      duration={[200, 100]}
      arrow
      animation="shift-away-subtle"
      visible={showMode ? isVisible && forceShow : undefined}
      disabled={disabled}
      content={
        showMode ? (
          <div ref={toolTip} data-tippy>
            {content}
          </div>
        ) : (
          content
        )
      }
      theme={
        innerContent ? "custom-theme-hover-register" : "custom-theme-hover"
      }
      // these throw warnings because they are not valid props for Tippy
      // flip={showMode ? false : undefined}
      // extraClasses
      onCreate={(instance) => {
        tippyInstanceRef.current = instance;
        instance.popper.classList.add(
          showMode && extraClasses
            ? String(extraClasses)
            : "custom-on-hover-tippy-class"
        );
      }}
      popperOptions={
        showMode && {
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
          ],
        }
      }
    >
      <div>{children}</div>
    </Tippy>
  );
}
